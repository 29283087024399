/**
 * Utilities
 */

.mi-wrapper {
   padding-left: 300px;
   position: relative;
   min-height: 100vh;
   z-index: 1;

   @media #{$layout-laptop} {
      padding-left: 260px;
   }

   @media #{$layout-notebook} {
      padding-left: 0;
   }

   @media #{$layout-tablet} {
      padding-left: 0;
   }

   @media #{$layout-mobile-lg} {
      padding-left: 0;
   }
}

.color-theme {
   color: $color-theme;
}

.mt-30-reverse {
   margin-top: -30px;
}

.mt-30 {
   margin-top: 30px;
}

.mi-padding-top {
   padding-top: 120px;

   @media #{$layout-tablet} {
      padding-top: 100px;
   }

   @media #{$layout-mobile-lg} {
      padding-top: 80px;
   }
}

.mi-padding-bottom {
   padding-bottom: 120px;

   @media #{$layout-tablet} {
      padding-bottom: 100px;
   }

   @media #{$layout-mobile-lg} {
      padding-bottom: 80px;
   }
}

.mi-section {
   position: relative;
}

.mi-bglines {
   position: absolute;
   left: 300px;
   top: 0;
   height: 100%;
   min-height: 100vh;
   width: calc(100% - 300px);
   z-index: -1;

   span {
      width: 1px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      min-height: 100vh;
      background: rgba($color-border, 0.3);

      &:nth-child(1) {
         left: 20%
      }

      &:nth-child(2) {
         left: 40%
      }

      &:nth-child(3) {
         left: 60%
      }

      &:nth-child(4) {
         left: 80%
      }
   }

   @media #{$layout-laptop} {
      left: 260px;
      width: calc(100% - 260px);
   }

   @media #{$layout-notebook} {
      left: 0;
      width: 100%;
   }

   @media #{$layout-tablet} {
      left: 0;
      width: 100%;
   }

   @media #{$layout-mobile-lg} {
      left: 0;
      width: 100%;
   }
}

.size-md[class^="lni-"],
.size-lg[class^="lni-"],
.size-sm[class^="lni-"],
.size-xs[class^="lni-"] {
   font-size: inherit !important;
}
.icon-upwork {
   display:inline-block;
   height: 25px; 
   padding: 0px 0px 3px 0px;
   text-align: center;
   // min-width: 20px;
   fill: #a4acc4;
}
.icon-upwork:hover {
   display:inline-block;
   height: 25px; 
   padding: 0px 0px 3px 0px;
   text-align: center;
   // min-width: 20px;
   fill: #04b790;
}
.icon-freelancer {
   display:inline-block;
   height: 20px;
   padding: 1px 1px 1px 1px;
   fill: #a4acc4;
}
.icon-freelancer:hover {
   display:inline-block;
   height: 20px;
   padding: 1px 1px 1px 1px;
   fill: #04b790;
}
.img-flag {
  box-sizing: content-box;
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  border: 10px solid #F1BF00;
  margin: 20px;

  &:after{
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    border: 10px solid #AA151B;
    z-index: -1;
    border-radius: 50%;
  }
  img{
    border: 10px solid #AA151B;
    border-radius: 50%;
    display: block;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
  }
}